import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import service from "../core/service/v1";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");

  const [selectBtn, setSelectBtn] = useState("yes");
  const [selectNoBtn, setSelectNoBtn] = useState("no");
  const { t } = useTranslation();

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  // CHANGE BUTTON COLORS YES-NO
  const yesBtnClick = () => {
    setSelectBtn("yes");
  };
  const noBtnClick = () => {
    setSelectBtn("no");
  };

  // CHANGE BUTTON COLORS NO-YES
  const yesClick = () => {
    setSelectNoBtn("yes");
  };
  const noClick = () => {
    setSelectNoBtn("no");
  };

  // FUNCTION AUTHOR JOTHI================

  const Industries = [
    { value: "Law/Notary", label: t("law/notary") },
    { value: "Art/Antiques", label: t("art-ntiques") },
    { value: "Public Company", label: t("public-company") },
    { value: "Currency Exchange Office", label: t("currency-exchange-office") },
    { value: "Casino/Betting", label: t("casino-betting") },
    {
      value: "Construction/Real Estate Development",
      label: "Construction/Real Estate Development",
    },
    { value: "Wealth Management", label: t("wealth-manage") },
    { value: "Information Technology", label: t("info-tech") },
    { value: "Precious Metals", label: t("precious-metals") },
    { value: "Import-Export", label: t("import-export") },
    { value: "Sports Company", label: t("sports-company") },
    {
      value: "Holding/Instrumental Company",
      label: t("holding-company"),
    },
    { value: "Others", label: t("others") },
  ];

  const initialFormValue = {
    legalEntityName: "",
    alternateName: "",
    bussinessAddress: "",
    bussinessunit: "",
    bussinesscity: "",
    bussinesspostalCode: "",
    bussinescountry: "",
    validAddressAuthority: "",
    bussinesWebsite: "",
    bussinesIndustry: "",
    service: "",
    businessconsidrationstatus: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [legalnameErrMsg, setlegalnameErrMsg, legalnameErrMsgref] =
    useState("");
  const [legalNamevallidate, setlegalNamevallidate, legalNamevallidateref] =
    useState(false);
  const [AlternateNameErrMsg, setAlternateNameErrMsg, AlternateNameErrMsgref] =
    useState("");
  const [
    AlternateNameValidate,
    setAlternateNameValidate,
    AlternateNameValidateref,
  ] = useState(false);
  const [
    BusinessAddressErrMsg,
    setBusinessAddressErrMsg,
    BusinessAddressErrMsgref,
  ] = useState("");
  const [
    BusinessAddressValidate,
    setBusinessAddressValidate,
    BusinessAddressValidateref,
  ] = useState(false);
  const [BusinessCityErrMsg, setBusinessCityErrMsg, BusinessCityErrMsgref] =
    useState("");
  const [
    BusinessCityValidate,
    setBusinessCityValidate,
    BusinessCityValidateref,
  ] = useState(false);
  const [PostalCodeErrMsg, setPostalCodeErrMsg, PostalCodeErrMsgref] =
    useState("");
  const [PostalCodeValidate, setPostalCodeValidate, PostalCodeValidateref] =
    useState(false);
  const [WebsiteErrMsg, setWebsiteErrMsg, WebsiteErrMsgref] = useState("");
  const [WebsiteValidate, setWebsiteValidate, WebsiteValidateref] =
    useState(false);
  const [ServiceErrMsg, setServiceErrMsg, ServiceErrMsgref] = useState("");
  const [ServiceValidate, setServiceValidate, ServiceValidateref] =
    useState("");
  const [IndustryErrMsg, setIndustryErrMsg, IndustryErrMsgref] = useState("");
  const [IndustryValidate, setIndustryValidate, IndustryValidateref] =
    useState(false);
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState("");
  const [countryValidate, setcountryValidate, countryValidateref] =
    useState(false);

  const {
    legalEntityName,
    alternateName,
    bussinessAddress,
    bussinessunit,
    bussinesscity,
    bussinesspostalCode,
    bussinescountry,
    validAddressAuthority,
    bussinesWebsite,
    service,
    bussinesIndustry,
    businessconsidrationstatus,
  } = formValue;

  useEffect(() => {
    getStep();
  }, [0]);

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      let sanitizedValue = value.replace(/\s/g, "");
      let formData = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(formData);
      validation_2();
    } catch (err) {
      console.log("handlechange eror for kyp1", err);
    }
  };

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      bussinescountry: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleIndustryChange = (selectedIndustry) => {
    const updatedFormValue = {
      ...formValue,
      bussinesIndustry: selectedIndustry ? selectedIndustry.value : "",
    };
    setFormValue(updatedFormValue);
  };

  const validation_2= () => {
    try {
      // Legal Entity Name validation
      if (formValue.legalEntityName === "") {
        setlegalnameErrMsg(t("legal-entity-name-required"));
        setlegalNamevallidate(true);
      } else if (!/^[a-zA-Z]/.test(formValue.legalEntityName)) {
        setlegalnameErrMsg(t("legal-entity-name-must-start-letter"));
        setlegalNamevallidate(true);
      } else if (
        formValue.legalEntityName.length < 3 ||
        formValue.legalEntityName.length > 25
      ) {
        setlegalnameErrMsg(t("legal-entity-name-b/w-3-25-char"));
        setlegalNamevallidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.legalEntityName)) {
        setlegalnameErrMsg(
          t("legal-entity-name-contain-letters-num-underscore")
        );
        setlegalNamevallidate(true);
      }
      // else {
      //   setlegalNamevallidate(false);
      // }

      // Alternate Name validation
     else if (formValue.alternateName === "") {
        setAlternateNameErrMsg(t("alternate-name-required"));
        setAlternateNameValidate(true);
        setlegalNamevallidate(false);

      }
      // else {
      //   setAlternateNameValidate(false);
      // }

      // Business Address validation
     else if (formValue.bussinessAddress === "") {
        setBusinessAddressErrMsg(t("business-address-required"));
        setBusinessAddressValidate(true);
      }
      // else {
      //   setBusinessAddressValidate(false);
      // }

      // City validation
      if (formValue.bussinesscity === "") {
        setBusinessCityErrMsg(t("city-required-field"));
        setBusinessCityValidate(true);
      } 
      // else {
      //   setBusinessCityValidate(false);
      // }

      // Postal Code validation
     else if (formValue.bussinesspostalCode === "") {
        setPostalCodeErrMsg(t("postal-code-required"));
        setPostalCodeValidate(true);
      } else if (!/^[0-9]{5,10}$/.test(formValue.bussinesspostalCode)) {
        setPostalCodeErrMsg(t("postal-code-b/w-5-10-digits"));
        setPostalCodeValidate(true);
      }
      // else {
      //   setPostalCodeValidate(false);
      // }

      // Website validation
    else if (formValue.bussinesWebsite.trim() === "") {
        setWebsiteErrMsg(t("business-website-required"));
        setWebsiteValidate(true);
      } else if (
        formValue.bussinesWebsite.toLowerCase() !== "n/a" ||
        /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/[\w\d-]*)*\/?$/.test(
          formValue.bussinesWebsite.trim()
        )
      ) {
        setWebsiteErrMsg(t("enter-valid-website-url"));
        setWebsiteValidate(true);
       
      }
     

      // Service validation
     else if (formValue.service === "") {
        setServiceErrMsg(t("service-required"));
        setServiceValidate(true);
      }
      
      // else {
      //   setServiceValidate(false);
      // }

      // Country validation
     else if (formValue.bussinescountry === "") {
        setcountryErrMsg(t("country-required-field"));
        setcountryValidate(true);
      }
      
      // else {
      //   setcountryValidate(false);
      // }

      // Industry validation
     else if (formValue.bussinesIndustry === "") {
        setIndustryErrMsg(t("industry-required"));
        setIndustryValidate(true);
      } else {
        setIndustryValidate(false);
        setPostalCodeValidate(false);
        setcountryValidate(false);
        setServiceValidate(false);
        setWebsiteValidate(false); 
        setBusinessCityValidate(false);
        setBusinessAddressValidate(false);
        setAlternateNameValidate(false);
        setlegalNamevallidate(false);
      }
    } catch (err) {
      console.log("Validation error for kyp1", err);
    }
  };
  const validation = () => {
    try {
      // Legal Entity Name validation
      if (formValue.legalEntityName === "") {
        setlegalnameErrMsg(t("legal-entity-name-required"));
        setlegalNamevallidate(true);
      } else if (!/^[a-zA-Z]/.test(formValue.legalEntityName)) {
        setlegalnameErrMsg(t("legal-entity-name-must-start-letter"));
        setlegalNamevallidate(true);
      } else if (
        formValue.legalEntityName.length < 3 ||
        formValue.legalEntityName.length > 25
      ) {
        setlegalnameErrMsg(t("legal-entity-name-b/w-3-25-char"));
        setlegalNamevallidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.legalEntityName)) {
        setlegalnameErrMsg(
          t("legal-entity-name-contain-letters-num-underscore")
        );
        setlegalNamevallidate(true);
      }
      else {
        setlegalNamevallidate(false);
      }

      // Alternate Name validation
      if (formValue.alternateName === "") {
        setAlternateNameErrMsg(t("alternate-name-required"));
        setAlternateNameValidate(true);
      }
      else {
        setAlternateNameValidate(false);
      }

      // Business Address validation
      if (formValue.bussinessAddress === "") {
        setBusinessAddressErrMsg(t("business-address-required"));
        setBusinessAddressValidate(true);
      }
      // else {
      //   setBusinessAddressValidate(false);
      // }

      // City validation
      if (formValue.bussinesscity === "") {
        setBusinessCityErrMsg(t("city-required-field"));
        setBusinessCityValidate(true);
      } 
      else {
        setBusinessCityValidate(false);
      }

      // Postal Code validation
      if (formValue.bussinesspostalCode === "") {
        setPostalCodeErrMsg(t("postal-code-required"));
        setPostalCodeValidate(true);
      } else if (!/^[0-9]{5,10}$/.test(formValue.bussinesspostalCode)) {
        setPostalCodeErrMsg(t("postal-code-b/w-5-10-digits"));
        setPostalCodeValidate(true);
      }
      else {
        setPostalCodeValidate(false);
      }

      // Website validation
     if (formValue.bussinesWebsite.trim() === "") {
        setWebsiteErrMsg(t("business-website-required"));
        setWebsiteValidate(true);
      } else if (
        formValue.bussinesWebsite.toLowerCase() !== "n/a" ||
        /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/[\w\d-]*)*\/?$/.test(
          formValue.bussinesWebsite.trim()
        )
      ) {
        setWebsiteErrMsg(t("enter-valid-website-url"));
        setWebsiteValidate(true);
       
      }
     

      // Service validation
     else if (formValue.service === "") {
        setServiceErrMsg(t("service-required"));
        setServiceValidate(true);
      }
      
      // else {
      //   setServiceValidate(false);
      // }

      // Country validation
     else if (formValue.bussinescountry === "") {
        setcountryErrMsg(t("country-required-field"));
        setcountryValidate(true);
      }
      
      // else {
      //   setcountryValidate(false);
      // }

      // Industry validation
     else if (formValue.bussinesIndustry === "") {
        setIndustryErrMsg(t("industry-required"));
        setIndustryValidate(true);
      } else {
        setIndustryValidate(false);
        setPostalCodeValidate(false);
        setcountryValidate(false);
        setServiceValidate(false);
        setWebsiteValidate(false); 
        setBusinessCityValidate(false);
        setBusinessAddressValidate(false);
        setAlternateNameValidate(false);
        setlegalNamevallidate(false);
      }
    } catch (err) {
      console.log("Validation error for kyp1", err);
    }
  };

  const submit = async (e) => {
    try {
      e.preventDefault();
      validation();
      if (
        !legalNamevallidateref.current &&
        !AlternateNameValidateref.current &&
        !BusinessAddressValidateref.current &&
        !BusinessCityValidateref.current &&
        !PostalCodeValidateref.current &&
        !WebsiteValidateref.current &&
        !IndustryValidateref.current &&
        !ServiceValidateref.current &&
        !countryValidateref.current
      ) {
        formValue["verifiedSteps"] = "Step-1";
        formValue["validAddressAuthority"] = selectBtn;
        formValue["businessconsidrationstatus"] = selectNoBtn;
        var data = {
          apiUrl: apiService.kybfirst,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KypInformation2");
        } else {
          toast.error(resp.Message);
        }
      } else {
        console.log("Please fix validation errors before proceeding.");
      }
    } catch (err) {
      console.log("submition part  eror for kyp1", err);
    }
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        formValue.legalEntityName = kybData.legalEntityName;
        formValue.alternateName = kybData.alternateName;
        formValue.bussinessAddress = kybData.bussinessAddress;
        formValue.bussinessunit = kybData.bussinessunit;
        formValue.bussinesscity = kybData.bussinesscity;
        formValue.bussinesspostalCode = kybData.bussinesspostalCode;
        formValue.bussinescountry = kybData.bussinescountry;
        formValue.validAddressAuthority = kybData.validAddressAuthority;
        formValue.bussinesWebsite = kybData.bussinesWebsite;
        formValue.service = kybData.service;
        formValue.bussinesIndustry = kybData.bussinesIndustry;
        formValue.businessconsidrationstatus =
          kybData.businessconsidrationstatus;
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/buycrypto">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">1</span>
                    {t("business-information")}
                  </h3>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("legal-entity-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="legalEntityName"
                          value={legalEntityName}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-legal-entity-name")}
                        />
                      </div>
                      {legalNamevallidateref.current && (
                        <p className="errorcss">{legalnameErrMsgref.current}</p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("alternate-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="alternateName"
                          value={alternateName}
                          onChange={handleChange}
                          className=""
                          placeholder={t("alternate-name-placeholder")}
                        />
                      </div>
                      {AlternateNameValidateref.current && (
                        <p className="errorcss">
                          {AlternateNameErrMsgref.current}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail1">
                      <h6 className="input-label">
                        {t("registered-business-address")}
                      </h6>
                      <div className="registered_add">
                        <div className="input-field ref_input regist_address_input">
                          <input
                            type="text"
                            name="bussinessAddress"
                            value={bussinessAddress}
                            onChange={handleChange}
                            placeholder={t("enter-date-of-formation")}
                          />
                        </div>

                        <div className="input-field ref_input">
                          <input
                            type="number"
                            name="bussinessunit"
                            value={bussinessunit}
                            onChange={handleChange}
                            className=""
                            placeholder={t("unit#")}
                          />
                        </div>
                      </div>
                      {BusinessAddressValidateref.current && (
                        <p className="errorcss">
                          {BusinessAddressErrMsgref.current}
                        </p>
                      )}

                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="bussinesscity"
                          value={bussinesscity}
                          onChange={handleChange}
                          className=""
                          placeholder="Spain"
                        />
                      </div>
                      {BusinessCityValidateref.current && (
                        <p className="errorcss">
                          {BusinessCityErrMsgref.current}
                        </p>
                      )}

                      <div className="input-groups fiat_deposit_name_details">
                        <div className="fiat_deposit_detail">
                          <div className="input-field ref_input">
                            <input
                              type="text"
                              name="bussinesspostalCode"
                              value={bussinesspostalCode}
                              onChange={handleChange}
                              className=""
                              placeholder={t("enter-zip-postal-code")}
                            />
                          </div>
                          {PostalCodeValidateref.current && (
                            <p className="errorcss">
                              {PostalCodeErrMsgref.current}
                            </p>
                          )}
                        </div>
                        {/* country dropdown */}
                        <div className="fiat_deposit_detail">
                          <div className="custom-select-stake kyc-verify my-2 w-100">
                            <div className="input-groups select-wrapper ">
                              <Select
                                options={options}
                                value={options.find(
                                  (option) => option.value === bussinescountry
                                )}
                                onChange={handleCountryChange}
                                placeholder={t("select-country")}
                                styles={colourStyles}
                                className="reg-select-dropdown "
                              />
                            </div>
                          </div>
                          {/* <div className="input-field ref_input kyc_country_dropdown"></div> */}
                          {countryValidateref.current && (
                            <p className="errorcss">
                              {countryErrMsgref.current}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="access_act_text">
                    <h3>
                      {t(
                        "is-this-address-where-your-company-physically-conducts"
                      )}
                    </h3>
                    <div className="access_act_text_btn mt-4">
                      <button
                        onClick={yesBtnClick}
                        className={
                          selectBtn === "yes" ? "yes_button" : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={noBtnClick}
                        className={
                          selectBtn === "no" ? "yes_button" : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("business-website")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="bussinesWebsite"
                          value={bussinesWebsite}
                          onChange={handleChange}
                          placeholder={t("business-website-link")}
                        />
                      </div>
                      {WebsiteValidateref.current && (
                        <p className="errorcss">{WebsiteErrMsgref.current}</p>
                      )}

                      <span className="text_website">
                        {t("if-no-website-put-n/a")}
                      </span>
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("industry")}</h6>
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={Industries}
                          value={Industries.find(
                            (option) => option.value === bussinesIndustry
                          )}
                          onChange={handleIndustryChange}
                          placeholder={t("select-industry")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    {IndustryValidateref.current && (
                      <p className="errorcss">{IndustryErrMsgref.current}</p>
                    )}
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("goods-services")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="service"
                          value={service}
                          onChange={handleChange}
                          placeholder={t("goods-services-placeholder")}
                        />
                      </div>
                      {ServiceValidateref.current && (
                        <p className="errorcss">{ServiceErrMsgref.current}</p>
                      )}
                    </div>
                  </div>
                  <div className="access_act_text">
                    <h3>
                      {t("is-your-business-considered-financial-institution")}
                    </h3>
                    <div className="access_act_text_btn mt-4">
                      <button
                        onClick={yesClick}
                        className={
                          selectNoBtn === "no" ? "no_button" : "yes_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={noClick}
                        className={
                          selectNoBtn === "yes" ? "no_button" : "yes_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="Submit my-4">
                    <button onClick={submit}>{t("next")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
