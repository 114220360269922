import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialFormValue = {
    mainIncome: [],
    mainFunds: [],
    netAnnual: "",
    otherIncome: "",
    otherFunds: "",
  };

  useEffect(() => {
    getStep();
  }, [0]);

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [mainIncomeValidate, setmainIncomeValidate, mainIncomeValidateref] =
    useState(false);
  const [mainFundsValidate, setmainFundsValidate, mainFundsValidateref] =
    useState(false);
  const [netAnnualValidate, setnetAnnualValidate, netAnnualValidateref] =
    useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { mainIncome, mainFunds, netAnnual, otherIncome, otherFunds } =
    formValue;

  const [incomeOptions, setIncomeOptions] = useState([
    { label: t("employ-income"), value: "Employment Income" },
    { label: t("capital-income"), value: "Capital Income" },
    { label: t("retirement-income"), value: "Retirement/Pension Income" },
    { label: t("public-benefits"), value: "Public benefits" },
    { label: t("no-income"), value: "No Income" },
    { label: t("others"), value: "Others" },
  ]);

  const [fundsOptions, setFundsOptions] = useState([
    { label: t("professional-income"), value: "Professional Income" },
    { label: t("savings"), value: "Savings" },
    { label: t("inheritance"), value: "Inheritance/Donation" },
    { label: t("sale-of-assets"), value: "Sale of assets" },
    { label: t("others"), value: "Others" },
  ]);

  const [netAnnualOptions, setnetAnnualOptions] = useState([
    { label: t("Up to €30,000"), value: "Up to €30,000" },
    { label: t("€30,001 to €60,000"), value: "€30,001 to €60,000" },
    { label: t("€60,001 to €90,000"), value: "€60,001 to €90,000" },
    { label: t("€90,001 to €120,000"), value: "€90,001 to €120,000" },
    { label: t("€120,001 to €150,000"), value: "€120,001 to €150,000" },
    { label: t("More than €150,000"), value: "More than €150,000" },
  ]);

  const handleIncomeChange = (event) => {
    const { value, checked } = event.target;
    let updatedIncome;

    if (checked) {
      updatedIncome = [...formValue.mainIncome, value];
    } else {
      updatedIncome = formValue.mainIncome.filter((item) => item !== value);
    }

    const updatedFormValue = { ...formValue, mainIncome: updatedIncome };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleFundsChange = (event) => {
    const { value, checked } = event.target;
    let updatedFunds;

    if (checked) {
      updatedFunds = [...formValue.mainFunds, value];
    } else {
      updatedFunds = formValue.mainFunds.filter((item) => item !== value);
    }

    const updatedFormValue = { ...formValue, mainFunds: updatedFunds };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleNetAnnualChange = (selectedOption) => {
    const updatedFormValue = { ...formValue, netAnnual: selectedOption.value };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleOtherIncomeChange = (event) => {
    const updatedFormValue = { ...formValue, otherIncome: event.target.value };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleOtherFundsChange = (event) => {
    const updatedFormValue = { ...formValue, otherFunds: event.target.value };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      console.log(resp.data);
      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.mainIncome.push(kycData.mainIncome);
        formValue.mainFunds = kycData.mainFunds;
        formValue.netAnnual = kycData.netAnnual;
        console.log(formValue.mainFunds,">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.")
        if (kycData.otherIncome !== "") {
          formValue.otherIncome = kycData.otherIncome;
        }
        if (kycData.otherFunds != "") {
          formValue.otherFunds = kycData.otherFunds;
        }
      } else {
        console.log("====");
      }
    } catch (err) {
      console.log(err, "====");
    }
  };

  const validate = (values) => {
    let errors = {};

    if (values.mainIncome.length === 0) {
      setmainIncomeValidate(true);
      errors.mainIncome = t("main-source-income-required");
    } else if (values.mainIncome.includes("Others") && !values.otherIncome) {
      setmainIncomeValidate(true);
      errors.otherIncome = t("please-specify-your-income-level");
    }
    // else {
    //   setmainIncomeValidate(false);
    // }
    else if (values.mainFunds.length === 0) {
      setmainFundsValidate(true);
      errors.mainFunds = t("main-source-fund-required");
    } else if (values.mainFunds.includes("Others") && !values.otherFunds) {
      setmainFundsValidate(true);
      errors.otherFunds = t("please-specify-your-fund-source");
    }
    // else {
    //   setmainFundsValidate(false);
    // }
    else if (values.netAnnual == "") {
      setnetAnnualValidate(true);
      errors.netAnnual = t("net-annual-income-level-required");
    } else {
      setnetAnnualValidate(false);
      setmainFundsValidate(false);
      setmainIncomeValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const submit = async () => {
    try {
     validate(formValue);
      formValue["verifiedSteps"] = "Step-3";
      if (
        mainIncomeValidateref.current === false &&
        mainFundsValidateref.current === false &&
        netAnnualValidateref.current === false
      ) {
        var data = {
          apiUrl: apiService.kycthird,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KycInformation4");
        } else {
          toast.error(resp.Message);
        }
      } else {
      }
    } catch (error) {
      console.error("Error submitting the form", error);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation2">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">3</span>
                    {t("financial-account-holder-info")}
                  </h3>

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("what-are-your-main-source-income")}
                    </h6>

                    <div className="input-checkboxs">
                      <ul>
                        {incomeOptions.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.mainIncome.includes(
                                option.value
                              )}
                              onChange={handleIncomeChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                      {formValue.mainIncome.includes("Others") && (
                        <div className="input-groups icons mt-3">
                          <h6 className="input-label">
                            {t("please-specify-main-source-income")}{" "}
                          </h6>
                          <div className="input-field ref_input upload_icon">
                            <input
                              type="text"
                              value={formValue.otherIncome}
                              onChange={handleOtherIncomeChange}
                              className=""
                              placeholder={t("please-specify")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {validationnErr && validationnErr.mainIncome && (
                      <p className="errorcss">{validationnErr.mainIncome}</p>
                    )}
                    {validationnErr && validationnErr.otherIncome && (
                      <p className="errorcss">{validationnErr.otherIncome}</p>
                    )}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">{t("main-source-of-funds")}</h6>

                    <div className="input-checkboxs">
                      <ul>
                        {fundsOptions.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`funds-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.mainFunds.includes(
                                option.value
                              )}
                              onChange={handleFundsChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`funds-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                      {formValue.mainFunds.includes("Others") && (
                        <div className="input-groups icons mt-3">
                          <h6 className="input-label">
                            {t("please-specify-main-source-income")}
                          </h6>
                          <div className="input-field ref_input upload_icon">
                            <input
                              type="text"
                              value={formValue.otherFunds}
                              onChange={handleOtherFundsChange}
                              className=""
                              placeholder={t("please-specify")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {validationnErr && validationnErr.mainFunds && (
                      <p className="errorcss">{validationnErr.mainFunds}</p>
                    )}
                    {validationnErr && validationnErr.otherFunds && (
                      <p className="errorcss">{validationnErr.otherFunds}</p>
                    )}
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("indicate-your-net-annual-income")}
                    </h6>
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={netAnnualOptions}
                          value={netAnnualOptions.find(
                            (option) => option.value === formValue.netAnnual
                          )}
                          onChange={handleNetAnnualChange}
                          placeholder={t("select")}
                          className="reg-select-dropdown"
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    {validationnErr && validationnErr.netAnnual && (
                      <p className="errorcss">{validationnErr.netAnnual}</p>
                    )}
                  </div>

                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={submit}>{t("next")}</button>
                    ) : (
                      <button>{t("loading")} ...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
