import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData3";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { env } from "../core/service/envconfig";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

const Staking = () => {
  const [myhistory, setmyhistory] = useState("openorder");

  const myhistoryfunction = (newValue) => {
    setmyhistory(newValue);
  };

  const [refferalLink, setrefferalLink] = useState("");
  const [refferalCode, setrefferalCode] = useState("");
  const [refferHistoty, setRefferHistoty] = useState("");
  const [totalPage, setTotalPages] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [totalQualReferral, setTotalQualReferral] = useState("");
  const [totalRefferal, setTotalRefferal] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getReferralDetails(0);
    getReward();
    // referralHistory(0);
    getSiteSettings();
  }, []);

  const title = t("welcome-to-fidex");
  const getReferralDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        var link = resp.refer_link;
        var code = resp.data.referralCode;
        setrefferalLink(link);
        setrefferalCode(code);
      } else {
      }
    } catch (error) {}
  };
  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      showSuccessToast(t("referral-detail-copied-successfully"));
    } else {
      showSuccessToast(t("link-not-copied-please-try-after-sometimes"));
    }
  };
  const getReward = async () => {
    var get = {
      apiUrl: apiService.getReward,
    };
    setSiteLoader(true);
    var response = await getMethod(get);
    setSiteLoader(false);
    setTotalRefferal(response.data.totalChildren);
    setTotalQualReferral(response.data.qualifiedChildren);
    setRefferHistoty(response.data.history);
    setTotalRewards(response.data.totalBonus);
  };

const [sitedatas,setsitedatas]=useState('')
  const getSiteSettings = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "resp==-=-=-=-=-=-resp");
      if (resp.status == true) {
        setsitedatas(resp.data);
      }
    } catch (err) {
      console.log(err, "=-=-=-=");
    }
  };

  
  // const referralHistory = async (page) => {
  //     try {
  //         var payload = {
  //             perpage: 5,
  //             page: page,
  //         };
  //         var data = {
  //             apiUrl: apiService.referralHistory,
  //             payload: payload,
  //         };
  //         var resp = await postMethod(data);
  //         if (resp.status) {
  //             setRefferHistoty(resp.data.data);
  //             setTotalPages(resp.data.total);
  //         }
  //     } catch (error) { }
  // };
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (event, pageNumber) => {
    // referralHistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="fidex_refferal_content">
          <div className="staking-hero">
            <div className="container">
              <div className="row refeferal_section">
                <div className="col-lg-8">
                  <h4 className="stake-title invite_heading">
                    {t("invite-your-friends")}
                    <br />
                    <span className="span_text font-bitter">
                      00.00 USD
                    </span>{" "}
                    {t("invite-reward")}
                  </h4>
                </div>

                <div className="col-lg-4">
                  <div className="staking-rewards">
                    <div className="staking-flex">
                      <h4 className="invite_text">{t("invite-using-this")}</h4>
                    </div>
                    <div className="refferal_code">
                      <h4>{t("referral-code")}</h4>
                      <div className="">
                        <input
                          value={refferalCode == undefined ? "" : refferalCode}
                          readOnly
                          type="text"
                        />
                        <i
                          class="ri-file-copy-line cursor-pointer"
                          onClick={() => copy(refferalCode)}
                        ></i>
                      </div>
                    </div>
                    <div className="refferal_code">
                      <h4>{t("referral-link")}</h4>
                      <div className="">
                        <input
                          value={refferalLink == undefined ? "" : refferalLink}
                          readOnly
                          type="text"
                        />
                        <i
                          class="ri-file-copy-line cursor-pointer"
                          onClick={() => copy(refferalLink)}
                        ></i>
                      </div>
                    </div>
                    {/* <div className="invite_btn">
                    <button onClick={() => copy(refferalLink)}>Invite And Earn</button>
                  </div> */}
                    <div className="ref_new_main mt-5">
                      <div className="ref_new_social cursor-pointer">
                        <FacebookShareButton url={refferalLink} title={title}>
                          <FacebookIcon size={30} round />
                        </FacebookShareButton>
                        {/* <img src={require("../assets/Facebook-ref.png")} className="ref-social-new" /> */}
                      </div>
                      <div className="ref_new_social cursor-pointer">
                        <TwitterShareButton url={refferalLink} title={title}>
                          <TwitterIcon size={30} round />
                        </TwitterShareButton>
                        {/* <img src={require("../assets/twitter-ref.png")} className="ref-social-new" /> */}
                      </div>
                      <div className="ref_new_social cursor-pointer">
                        {/* <img src={require("../assets/tele-ref.png")} className="ref-social-new" /> */}
                        <LinkedinShareButton
                          url={refferalLink}
                        >
                          <LinkedinIcon size={30} round />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="my-refferal-eraning-section">
            <div className="container">
              <div className="my-refferal-eraning">
                <h2 className="my-refferal-eraning-title opt-title">
                  {t("my-referral-earnings")}
                </h2>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="referral_content_box">
                      <div className="referral_content_text">
                        <img src={require("../assets/person_icon.png")} />
                        <p className="font-satoshi">
                          {t("total-no of friends")}
                        </p>
                      </div>
                      <h4>
                        {totalRefferal != null &&
                        totalRefferal != undefined &&
                        totalRefferal != ""
                          ? totalRefferal
                          : 0}
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="referral_content_box">
                      <div className="referral_content_text">
                        <img src={require("../assets/person_icon.png")} />
                        <p className="font-satoshi">{t("no-of-qualified")}</p>
                      </div>
                      <h4>
                        {totalQualReferral != null &&
                        totalQualReferral != undefined &&
                        totalQualReferral != ""
                          ? totalQualReferral
                          : 0}
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="referral_content_box">
                      <div className="referral_content_text">
                        <img
                          src={require("../assets/gift_icon.png")}
                          alt="total-rewards"
                          className="total-ref-img"
                        />
                        <p className="font-satoshi">{t("total-rewards")}</p>
                      </div>
                      <h4>
                        {totalRewards != null &&
                        totalRewards != undefined &&
                        totalRewards != ""
                          ? parseFloat(totalRewards).toFixed(6)
                          : 0.0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* staking options */}
          <div className="table_padding_bottom">
            <div className="container">
              <div className="staking-flex">
                <h5 className="opt-title">{t("referral-history")}</h5>
                <Link to="/referralHistory">
                  <div className="ref_view_main">
                    <span className="invite_text">{t("view-all")}</span>
                    <i
                      class="ri-arrow-right-s-line"
                      style={{ color: "#33C2B9" }}
                    ></i>
                  </div>
                </Link>
              </div>

              <div className="table-responsive table-cont dash_table_content">
                <table className="table ">
                  <thead className="ref_table_head">
                    <tr className="stake-head font-satoshi">
                      <th className="table_center_text">S.No</th>
                      <th className="table_center_text">{t("username")}</th>
                      <th className="table_center_text">{t("date-time")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {refferHistoty.length > 0 ? (
                      refferHistoty.slice(0, 5).map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {i + 1}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {item.username}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text">
                              {moment(item.createdDate).format("lll")}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img">
                              <img
                                src={require("../assets/No-data.png")}
                                width="100px"
                              />
                            </div>
                            <div className="no_records_text">
                              {t("no-records-found")}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* {refferHistoty.length > 0 ? (
              <div className="pagination">
                <Stack spacing={2}>
                <Pagination
                    count={Math.ceil(totalPage / 5)}
                    page={currentPage}
                    onChange={handlePageChange}
                    size="small" 
                    renderItem={(item) => (
                        <PaginationItem
                            slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                            }}
                            {...item}sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: "#33c2b9",
                                        color: "#fff",
                                      },
                                    }}
                        />
                    )}
                />
                </Stack>
              </div>
              ) : ("") } */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Staking;
