import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Lightlogo from "../assets/Logo.png";
import Darklogo from "../assets/Logo.png";
import UserIcon from "../assets/account.png";
import securitylow from "../assets/security.png";
import securitymedium from "../assets/Securitymedium.png";
import security from "../assets/Securityhigh.png";
import logout from "../assets/logout.png";
import arrow from "../assets/arrow.png";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Wallet from "@mui/icons-material/Wallet";
import { Link, useNavigate } from "react-router-dom";
import { removeAuthorization } from "../core/service/axios";
import { removeAuthToken } from "../core/lib/localStorage";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Sidebar from "../Pages/Side_bar";
import env from "../core/service/envconfig";
import { useTranslation } from "react-i18next";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [Theme, setTheme] = useState(localStorage.getItem("theme"));
  const { t, i18n } = useTranslation();

  const handleThemeToggle = () => {
    let theme = localStorage.getItem("theme");

    if (theme === "dark") {
      localStorage.setItem("theme", "light");
      setTheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      document.body.classList.add("dark");
      setTheme("dark");
    }
    window.location.reload();
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [profileData, setprofileData] = useState("");
  const [themeToggle, setThemeToggle] = useState(
    localStorage.getItem("Mode") || "light"
  );

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const[tfastatus, settfastatus] = useState();
 const [status, setstatus] = useState();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
      getProfile();
    } else {
      setloginCheck(false);
    }
    getTheme();

    settfastatus(localStorage.getItem("tfa_status"));
    Anti_status_check();
  }, []);

  const Anti_status_check =async  ()=>{
    var data = {
      apiUrl: apiService.Anti_status_check,
    };
    var resp = await getMethod(data);

    if (resp.status == true) {
      setstatus(resp.PhishinStatus);
  }
}
  const getTheme = () => {
    const theme = localStorage.getItem("theme");
    if (theme !== null) {
      if (theme === "dark") {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      document.body.classList.add("dark");
    }
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status == true) {
        setprofileData(resp.data);
      }
    } catch (error) {}
  };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 5);
    return `${firstFive}***@${domainPart}`;
  };

  const [open1, setOpen] = useState(false);

  const navigate = useNavigate();

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/login");
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success(t("uid-copied"));
  };

  // const [mode, setMode] = useState("pro");
  const handleLightToggle = () => {
    setThemeToggle("light");
    localStorage.setItem("Mode", "light");
    window.location.reload();
  };

  const handleProToggle = () => {
    setThemeToggle("pro");
    localStorage.setItem("Mode", "pro");
    window.location.reload();
  };
  <Sidebar data={themeToggle} />;

  // const { i18n, t } = useTranslation();
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleLanguageChange = (language) => {
  //   i18n.changeLanguage(language);
  //   localStorage.setItem("i18nextLng", language);
  //   setIsDropdownOpen(false);
  // };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language ? language : "en"); // Store the language in localStorage
    setIsDropdownOpen(false); // Close the dropdown after language selection
    window.location.reload();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const changeLanguage = (lng) => {
    console.log(lng);
    // let loc = "http://localhost:3000/";
    // window.location.replace(
    //     loc + "?lng=" +lng
    // );
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <header className="scrolled">
        <div className="">
          <div className="header_new_mega">
            <div className={`${classes.root} `}>
              <AppBar position="static">
                <Toolbar className="container">
                  <Typography variant="h6" className={classes.title}>
                    <div className="logo_new_sectio d-flex">
                      <Link to="/" className="logo-brand">
                        <img
                          src={Lightlogo}
                          className="img-fluid fidex_logo_light"
                          alt="logo"
                        />
                        <img
                          src={require("../../src/assets/fidex_dark_logo.webp")}
                          className="fidex_dark_logo"
                        />
                      </Link>

                      <span className="ml-4 das">|</span>

                      <div className={`menu_new_typr ${classes.appBarItems}`}>
                        <a
                          href="/buycrypto"
                          color="inherit"
                          className="contact_button"
                        >
                          {t("buycrpto")}
                        </a>
                        
                        {loginCheck == true ? 
                        themeToggle == "pro" ? (
                          <>
                            <a
                              href="/market"
                              color="inherit"
                              className="contact_button"
                            >
                              {t("market")}
                            </a>
                            <a
                              href="/trade/ETH_USDC"
                              color="inherit"
                              className="contact_button"
                            >
                              {t("trade")}
                            </a>
                          </>
                        ) : (
                          ""
                        )
                        : (
                          <>
                            <a
                              href="/market"
                              color="inherit"
                              className="contact_button"
                            >
                              {t("market")}
                            </a>
                            <a
                              href="/trade/ETH_USDC"
                              color="inherit"
                              className="contact_button"
                            >
                              {t("trade")}
                            </a>
                          </>
                        )}

                        <a
                          href="/swap"
                          color="inherit"
                          className="contact_button"
                        >
                          {t("convert")}
                        </a>

                        <a
                          href="/staking"
                          color="inherit"
                          className="contact_button"
                        >
                          {t("staking")}
                        </a>
                        {themeToggle == "pro" ? (
                          <a
                            href="/refferal"
                            color="inherit"
                            className="contact_button"
                          >
                            {t("referral")}
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                  >
                    <Link className="contact_button" to="/stake">
                      <img
                        src={require("../assets/download (1).png")}
                        className="moons"
                        width="30px"
                        alt="download"
                      />
                    </Link>

                    {Theme == "dark" ? (
                      <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/Day.png")}
                          width="28px"
                          alt="day"
                        />
                      </Link>
                    ) : (
                      <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/moon.png")}
                          width="28px"
                          alt="moon"
                        />
                      </Link>
                    )}
                    <>


                    <div className="relative">
                      {/* Trigger dropdown on click */}
                      <Link className="contact_button" onClick={toggleDropdown}>
                        <img
                          src={require("../assets/Globe.png")}
                          width="28px"
                          alt="Globe"
                        />
                      </Link>

                      {/* Dropdown menu */}
                      {isDropdownOpen && (
                        <ul className="globe-lists">
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("en")}
                          >
                            English
                          </li>
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("es")}
                          >
                            Spanish
                          </li>
                        </ul>
                      )}
                    </div>
                    {loginCheck==true ?
                     <h6
                     aria-controls="simple-menu"
                     aria-haspopup="true"
                     className=" head-drops"
                     onClick={handleClick}
                   >
                     <img
                       src={require("../assets/profile.png")}
                       className="minimage"
                       width="30px"
                       alt="profile-icon"
                     />
                   </h6>:""
                    }
                     
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem>
                          <LogoutIcon />
                          <span className="mx-3">Disconnect</span>{" "}
                        </MenuItem>
                      </Menu>
                    </>

                    <MenuIcon className="meus" onClick={handleMobileMenuOpen} />
                  </IconButton>

                  <div className={`menu_new_typr ${classes.appBarItems}`}>
                    {loginCheck == true ? (
                      <>
                          <Link to="/deposit">
                            {" "}
                            <button className="head-btn">{t("deposit")}</button>
                          </Link>
                       

                        <Link className="contact_button" to="/assets">
                          {t("assets")}
                        </Link>

                        <>
                          <h6
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className=" head-drops"
                            onClick={handleClick}
                          >
                            <img
                              src={require("../assets/profile.png")}
                              width="28px"
                              className="dark_display_none"
                              alt="profile-icon"
                            />
                            <img
                              src={require("../../src/assets/profile_dark.png")}
                              className="dark_profile"
                              alt="dark"
                            />
                          </h6>

                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <div className="frame-container">
                              {/* user details */}
                              <div className="user-details">
                                <img
                                  src={UserIcon}
                                  alt="usericon"
                                  className="user-img"
                                />
                                <div className="details">
                                  <span className="details-mail">
                                    {obfuscateEmail(profileData.email)}
                                  </span>
                                  <span className="details-udi">
                                    UID:{profileData.uuid}{" "}
                                    <i
                                      className="cursor-pointer fa-regular fa-copy"
                                      onClick={() => copy(profileData.uuid)}
                                    ></i>{" "}
                                  </span>
                                </div>
                              </div>
                              {/* links */}
                              <ul className="links">
                                <li>
                                  <Link
                                    to="/dashboard"
                                    className="security-link"
                                  >
                                    {t("dashboard")}
                                    <span className="profile-link-arrow">
                                      <img
                                        src={arrow}
                                        alt="arrow-icon"
                                        className="arrow"
                                      />
                                    </span>
                                  </Link>
                                </li>
                                <li className="security-link">
                                  <Link to="/security">{t("security")}</Link>
                                  <span className="identify">
                                    {tfastatus == 0 && status=='false'?
                                    <img src={securitylow} alt="security" />
                                    :tfastatus == 0 || status=='false'? 
                                    <img src={securitymedium} alt="security" />
                                    :
                                    <img src={security} alt="security" />}
                                  </span>
                                </li>
                                <li className="security-link">
                                  <Link href="/kyc">{t("identification")}</Link>
                                  <Link to="/kyc">
                                    <div>
                                      {profileData.kycstatus == 1 ? (
                                        <>
                                          <span className="identify">
                                            Verified
                                          </span>
                                          <span>
                                            <img
                                              src={arrow}
                                              alt="arrow-icon"
                                              className="arrow"
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="identify">
                                            {t("not-verified")}
                                          </span>
                                          <span>
                                            <img
                                              src={arrow}
                                              alt="arrow-icon"
                                              className="arrow"
                                            />
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </Link>
                                </li>
                                {themeToggle === "pro" ? (
                                  <li>
                                    <Link
                                      to="/withdraw"
                                      className="security-link"
                                    >
                                      {t("withdraw")}{" "}
                                      <span className="profile-link-arrow">
                                        <img
                                          src={arrow}
                                          alt="arrow-icon"
                                          className="arrow"
                                        />
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {themeToggle=="pro"?
                                  <li>
                                  <Link to="/rewards" className="security-link">
                                    {t("rewards")}{" "}
                                    <span className="profile-link-arrow">
                                      <img
                                        src={arrow}
                                        alt="arrow-icon"
                                        className="arrow"
                                      />
                                    </span>
                                  </Link>
                                </li>
                                :""}
                                <li>
                                  <Link
                                    to="/depositHistory"
                                    className="security-link"
                                  >
                                    {t("history")}
                                    <span className="profile-link-arrow">
                                      <img
                                        src={arrow}
                                        alt="arrow-icon"
                                        className="arrow"
                                      />
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/support" className="security-link">
                                    {t("support")}
                                    <span className="profile-link-arrow">
                                      <img
                                        src={arrow}
                                        alt="arrow-icon"
                                        className="arrow"
                                      />
                                    </span>
                                  </Link>
                                </li>
                                {/* <li className="mb-3">
                                  <Link
                                    to="/settings"
                                    className="security-link"
                                  >
                                    {t("settings")}
                                    <span className="profile-link-arrow">
                                      <img
                                        src={arrow}
                                        alt="arrow-icon"
                                        className="arrow"
                                      />
                                    </span>
                                  </Link>
                                </li> */}
                              </ul>
                              {/* logout button */}
                              <div
                                className="btn-wrapper security-link"
                                onClick={logout}
                              >
                                <span className="user-btn">{t("signout")}</span>
                                <img
                                  src={require("../assets/logout.png")}
                                  alt="logoutimg"
                                  className="logout-img"
                                />
                              </div>
                            </div>
                          </Menu>
                          <div className="header-toggle-cont">
                            <button
                              onClick={handleLightToggle}
                              className={
                                themeToggle === "pro" ? "pro_btn" : "light_btn"
                              }
                            >
                              {t("lite")}
                            </button>
                            <button
                              onClick={handleProToggle}
                              className={
                                themeToggle === "light"
                                  ? "pro_btn"
                                  : "light_btn"
                              }
                            >
                              Pro
                            </button>
                          </div>
                        </>
                      </>
                    ) : (
                      <>
                        <Link
                          className="contact_button txt-primary"
                          to="/login"
                        >
                          {t("signin")}
                        </Link>
                        <Link to="/register">
                          {" "}
                          <button className="head-btn">{t("signup")}</button>
                        </Link>
                      </>
                    )}
                    <span className="das mar-lft-ryt">|</span>
                    {/* </Link> */}
                    {/* <Link className="contact_button" to="/">
                      <img src={require("../assets/Globe.png")} width="28px" />
                    </Link> */}
                    {/* <div
                      className="relative"
                      onMouseEnter={() => setIsDropdownOpen(true)}
                      onMouseLeave={() => setIsDropdownOpen(false)}
                    >
                      <Link className="contact_button">
                        <img
                          src={require("../assets/Globe.png")}
                          width="28px"
                        />
                      </Link>

                      {isDropdownOpen && (
                        <ul className="globe-lists">
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("en")}
                          >
                            {t("english")}
                          </li>
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("es")}
                          >
                            {t("spanish")}
                          </li>
                        </ul>
                      )}
                    </div> */}{" "}
                    <div className="relative">
                      {/* Trigger dropdown on click */}
                      <Link className="contact_button" onClick={toggleDropdown}>
                        <img
                          src={require("../assets/Globe.png")}
                          width="28px"
                          alt="Globe"
                        />
                      </Link>

                      {/* Dropdown menu */}
                      {isDropdownOpen && (
                        <ul className="globe-lists">
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("en")}
                          >
                            English
                          </li>
                          <li
                            className="globe-options"
                            onClick={() => handleLanguageChange("es")}
                          >
                            Spanish
                          </li>
                        </ul>
                      )}
                    </div>
                    <span className="das mar-lft-ryt">|</span>
                    {Theme == "dark" ? (
                      <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/Day.png")}
                          width="28px"
                        />
                      </Link>
                    ) : (
                      <Link className="contact_button">
                        <img
                          onClick={handleThemeToggle}
                          src={require("../assets/moon.png")}
                          width="28px"
                        />
                      </Link>
                    )}
                  </div>
                </Toolbar>
              </AppBar>

              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                onClose={handleMobileMenuClose}
                className={classes.drawer}
              >
                <div className={classes.mobileMenu}>
                  <div className="logo_new_sectio mobile_menu_icon">
                    <Link to="/" className="logo-brand">
                      <img
                        src={Darklogo}
                        className="m-3 img-fluid fidex_mobile_lightlogo"
                        alt="logo"
                      />
                      <img
                        src={require("../assets/fidex_dark_logo.webp")}
                        className="fidex_mobile_darklogo"
                      />
                    </Link>
                  </div>
                  {/*  */}

                  <div className="menu_statis">
                    <ListItem button className="drawa">
                      <Link to="/buycrypto"> {t("buycrpto")} </Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/market"> {t("market")}</Link>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Link to="/trade/ETH_USDC"> {t("trade")}</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/swap">{t("convert")}</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/staking">{t("staking")}</Link>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Link to="/refferal">{t("referral")}</Link>
                    </ListItem>
                    {loginCheck == true ? (
                      <>
                        <ListItem button className="drawa ">
                          <Link to="/assets">{t("assets")}</Link>
                        </ListItem>

                        <ListItem button className="drawa ">
                          <Link to="/dashboard">{t("dashboard")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/security">{t("security")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/deposit">{t("deposit")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/KycInformation1">
                            {t("identification")}
                          </Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/Withdraw">{t("withdraw")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/rewards">{t("rewards")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/depositHistory">{t("history")}</Link>
                        </ListItem>
                        <ListItem button className="drawa ">
                          <Link to="/settings">{t("settings")}</Link>
                        </ListItem>
                        <div className="header-toggle-cont">
                          <button
                            onClick={handleLightToggle}
                            className={
                              themeToggle === "pro" ? "pro_btn" : "light_btn"
                            }
                          >
                            Lite
                          </button>
                          <button
                            onClick={handleProToggle}
                            className={
                              themeToggle === "light" ? "pro_btn" : "light_btn"
                            }
                          >
                            Pro
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <ListItem button className="drawa">
                          <Link to="/login" className="drawar-signin">
                            {t("signin")}
                          </Link>
                        </ListItem>

                        <ListItem button className="drawa ">
                          <Link to="/register" className="drawar-signup">
                            {t("signup")}
                          </Link>
                        </ListItem>
                      </>
                    )}
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
